<template>
    <div class="index">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>

        <div class="index_body" v-show="!isQuiz">
            <div>
                <p class="index_body_info title" style="text-indent: 0;font-weight: bold; margin-bottom: 10px;">亲爱的会员朋友：</p>

                <p class="index_body_info">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;妙笔生花，下笔有神，邀请您共同体验水彩绘画的魅力！在秋冬季节，你是倾向于在家品茶读书，还是走上街头赏秋叶观晚霞？无论哪一种，这些平凡小事定格为我们内心充盈、惊喜的某个时刻。这次绘画创作活动，我们将带领大家从平凡细节入手，通过画笔，描绘出您对生活独一无二的洞察与感受。
                </p>

                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                       style='background:#ffffff;border-collapse:collapse;border:none;width:90%;margin: 0 auto'>
                    <tr>
                        <td style='border:solid rgb(253,133,84) 2.0pt'>
                            <ul style="list-style: square;padding-right: 15px">
                                <li>
                                    <b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动参与方式：</span></b><span
                                    style='font-family:"微软雅黑",sans-serif;color:black'>现场将提供相应工具及配套指导人员，在指导人员指引下完成绘画，活动将提供2种款式，数量有限，先到先得，现场道具提供以实际情况为准；</span>
                                    <!--
                                    <p style="margin: 0; padding: 0;text-align: center"><img src="../assets/image/gift.jpg" width="100%" /><br><span style="font-style: italic;font-size: 0.8em;color: #333">以上图片供参考，款式以活动现场分配为准</span></p>
                                    -->
                                    <ul class="package_ul">
                                        <input class="mirror_item" type="radio" style="display: none" id="gift_1" value="晚霞" v-model="staff.giftType">
                                        <label class="package_ul_item cup_spring" for="gift_1"><img src="../assets/image/gift_1.jpg" alt="">
                                            <span><i class="colored">选择一：晚霞</i><br>(适合新手)</span></label>

                                        <input class="coffee_item1" type="radio" style="display: none" id="gift_2" value="书房" v-model="staff.giftType">
                                        <label class="package_ul_item cup_fire" for="gift_2"><img src="../assets/image/gift_2.jpg" alt="">
                                            <span><i class="colored">选择二：书房</i><br>(较难，适合有色彩创作经验者)</span></label>
                                    </ul>

                                    <p style="font-style: italic;font-size: 0.8em;color: red; text-align: center">* 请选择款式，现场分配以报名为准，图片供参考。</p>
                                </li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动时间：</span></b><b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif;color:rgb(253,133,84)'>11月23日（周六）13:30-17:30</span></b></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动地点：</span></b><span>公司10.15&10.16会议室</span></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>特别提醒：</span></b>
                                    <ul>

                                        <li><b>本次活动为线下活动，不支持线上参与；</b></li>
                                        <li>本场限定25席，仅限会员本人参加。席位先到先得，以系统成功提交为准；</li>
                                        <li>如报名成功后，因故无法到场，请您提前联系 <a href="mailto:bjunion007f@163.com">bjunion007f@163.com</a> 取消报名，避免现场席位资源浪费。<b>同时，报名满员后，我们将开放部分候补名额，如遇会员取消报名，我们将根据候补登记顺序开放参与资格。</b>
                                        </li>
                                        <li><b>参与范围：</b>2024年10月1日前入职、目前仍在职的SAP北京分公司工会会员。</li>
                                    </ul>
                                </li>
                                <!--
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>本次活动为线下活动，不支持线上参与，
    限定<span lang=EN-US>30</span>席，仅限报名会员本人参与。</span></b><span style='font-family:
    "微软雅黑",sans-serif;color:black'>席位先到先得，以系统成功提交为准。席位有限，<b>如您报名成功后因故无法参与，</b></span><span
                                    lang=EN-US style='color:black'><a href="mailto:请您提前联系bjunion007f@163.com"><b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif'><span lang=EN-US>请您提前联系bjunion007f@163.com</span></span></b></a></span><b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif;color:black'> </span></b><b><span
                                    style='font-family:"微软雅黑",sans-serif;color:black'>取消报名，</span></b><span
                                    style='font-family:"微软雅黑",sans-serif;color:black'>避免现场席位资源浪费。同时，报名满员后，我们将开放部分候补名额，如遇会员取消报名，我们将根据候补登记顺序开放参与资格。</span>
                                </li>
                                <hr>
                                <li><b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#333333'>参与范围：</span></b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif;color:#333333'>2024</span><span
                                    style='font-family:"微软雅黑",sans-serif;color:#333333'>年<span lang=EN-US>4</span>月<span
                                    lang=EN-US>1</span>日前入职、目前仍在职的<span lang=EN-US>SAP</span>北京分公司工会</span><b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#C00000'>女性会员</span></b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#333333'>。</span></li>
                                    -->
                            </ul>
                        </td>
                    </tr>
                </table>

            </div>

            <div class="index_body_button_box">
                <button class="index_button online" @click="regist()" :disabled="isDisabled">{{ btnText }}</button>
            </div>

        </div>


        <div v-if="questionLoaded">
            <div v-show="isQuiz">
                <Quiz></Quiz>
            </div>
        </div>
        <!--      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>-->
    </div>
</template>

<script>
import Quiz from "../components/quiz"
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
    name: "login",
    components: {
        // Quiz,
    },
    data() {
        return {
            isQuiz: false,
            questionLoaded: false,
            isClick: true,
            stats: {
                status: false,
                submitted: 0,
                submitted_limit: 0,
                waiting: 0,
                waiting_limit: 0
            },
        }
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        }),
        isDisabled: function () {
            return this.staff.giftType==='' || !(this.stats.status === false && (this.stats.submitted < this.stats.submitted_limit || this.stats.waiting < this.stats.waiting_limit));
        },
        btnText: function () {
            if (this.stats.status) {
                return '已经报名'
            }
            else if(!this.giftType){
                return '请选择款式'
            }
            else if(this.stats.submitted<this.stats.submitted_limit){
                return '我要报名'
            } else if (this.stats.waiting < this.stats.waiting_limit) {
                return '报名已满，登记候补'
            } else {
                return '本场已满员'
            }
        },
        giftType: function () {
            return this.staff.giftType;
        }
    },
    watch: {
        giftType() {
            this.getStats(this.staff.giftType).then(result => {
                this.stats = result;
            })
        }
    },
    mounted() {
        // this.loadQuestion().then(() => {
        //     this.questionLoaded = true;
        // })
        this.getStats(this.staff.giftType).then(result => {
            this.stats = result;
        })
    },
    methods: {
        ...mapActions({
            'loadQuestion': 'initData',
            'getStats': 'getSubmitStats'
        }),
        regist() {
            // this.isQuiz = true

            console.log(this.isClick);
            if (this.isClick) {
                this.isClick = false
                axios.post('/submit', {score: 0, result: {}, staff: this.staff}).then(rsp => {
                    this.staff.msg = rsp.data.message;
                    this.$router.replace({name: 'result'})
                }).catch((e) => {
                    alert(e.response.data.message)
                    // this.isError = true
                    // this.message = e.response.data.message
                    this.isClick = true
                })
            }
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
    width 100%
    position relative

    .index_inner
        width 750px
        height 100%

        .index_banner
            width 100%

            .img
                width 100%;
                box-shadow 10px 10px 15px rgba(0, 0, 0, 0.2);

    .index_body
        width 750px
        //height 80vh
        position absolute
        top 300px
        display flex
        flex-direction column
        align-items center

        .purple
            color: $theme !important
            font-weight bold

        //justify-content space-around

        .index_body_info
            //text-indent 2em
            padding 0 55px
            margin 0 0 10px 0
            font-size 32px
            font-family "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
            font-weight 400
            color #000
            line-height 40px
            box-sizing border-box

            &.title
                font-size 36px

        .MsoTableGrid
            font-size 32px

            ul
                padding-left 1.5em

            li
                line-height 1.3em
                margin-bottom 5pt

        .index_body_img
            width 100%
            display flex
            flex-direction column
            align-items center

            img
                width 460px
                padding 10px 0

            span
                padding 10px 0

            .orspan
                font-size 40px
                font-weight bold
                //color red
                padding 20px 0

        .index_body_info_more
            padding-bottom 60px

        .index_body_button_box
            //width 100%
            padding 31px 75px

            .index_button
                buttonSet()

.package_ul
    list-style-type none
    padding 20px 0 0 0 !important
    margin 0
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content space-around
    .colored
        color $blue
    .package_ul_item
        width: 40%;
        //height: 270px;
        background-size 100%
        background-repeat no-repeat
        img
            width 100%
            border-radius 20px
    label
        border 6px solid $bcGray
        border-radius 20px
    input:checked+label
        border 6px solid $blue
    span
        display block
        text-align center
        font-style italic
        font-size 26px
</style>
